<template>
  <div style="padding: 39rem 30rem">
    <div class="hidden">
      {{ data.title }}
    </div>
    <div class="hidden-info">
      <el-row style="width: 100%">
        <el-col :span="8">
          <span>姓名</span>{{ data.parent_name }}
        </el-col>
        <el-col :span="8">
          <span>联系电话</span>{{ data.mobile }}
        </el-col>
        <el-col :span="8">
          <span>所属校区</span>{{ data.school_name }}
        </el-col>
      </el-row>
      <el-row style="width: 100%">
        <el-col :span="8">
          <span>关联学生</span>{{ data.student_name }}
        </el-col>
        <el-col :span="8">
          <span>学生身份证</span>{{ data.student_idcard }}
        </el-col>
        <el-col :span="8">
          <span>与学生关系</span>{{ data.relationship_name }}
        </el-col>
      </el-row>
    </div>
    <div class="body">
<!--      <span class="body-title">{{ data.title }}</span>-->
      <div class="body-body">
        {{ data.detail }}
      </div>
      <el-image v-if="data.upload && data.upload.length" :preview-src-list="[data.upload[0].full_url]" :src="data.upload[0].full_url" style="width: 240rem;height: 240rem;margin-top: 17rem">
      </el-image>
      <div v-if="data.upload && data.upload.length">
        <span>文件信息</span>
        <span style="margin-left: 32rem;color: #3491FA">{{ data.upload[0].basename }}</span>
      </div>
      <el-button style="margin-top: 48rem" type="primary" @click="$router.back()">返回</el-button>
    </div>
  </div>
</template>
<script>
// 这里可以导入其他文件（比如：组件，工具 js，第三方插件 js，json 文件，图片文件等等）
// 例如：import 《组件名称》 from '《组件路径》 ';
export default {
	_config:{"route":{"path":"details","meta":{"title":"详情"}}},
  // import 引入的组件需要注入到对象中才能使用
  components: {},
  props: {},
  data() {
    // 这里存放数据
    return {
      data: {}
    }
  },
  // 计算属性 类似于 data 概念
  computed: {},
  // 监控 data 中的数据变化
  watch: {},
  // 方法集合
  methods: {},
  // 生命周期 - 创建完成（可以访问当前this 实例）
  created() {
    this.$_axios2.get('api/mailbox/pc/get-mail-detail?id=' + this.$route.query.id).then(res => {
      this.data = res.data.data
    })
  },
  // 生命周期 - 挂载完成（可以访问 DOM 元素）
  mounted() {
  },
  beforeCreate() {
  },
  beforeMount() {
  }, // 生命周期 - 挂载之前
  beforeUpdate() {
  }, // 生命周期 - 更新之前
  updated() {
  }, // 生命周期 - 更新之后
  beforeDestroy() {
  }, // 生命周期 - 销毁之前
  destroyed() {
  }, // 生命周期 - 销毁完成
  activated() {
  } // 如果页面有 keep-alive 缓存功能,这个函数会触发
}
</script>
<style lang="scss" scoped>
.hidden {
  font-size: 20rem;
  color: #333333;
}

.hidden-info {
  margin-top: 23rem;
  height: 96rem;
  background: #FCFCFC;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  border-bottom: 1rem solid #CCCCCC;

  span {
    display: inline-block;
    width: 113rem;
    text-align: right;
    margin-right: 31rem;
  }
}

.body {
  padding: 23rem;

  .body-body {
    //margin-top: 32rem;
    text-indent: 32rem;
    line-height: 32rem;
    letter-spacing: 2rem
  }
}
</style>
